import React, { useState } from 'react'
import { CiMenuFries } from "react-icons/ci";
import './SkinCareHeader.css'
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { FaWhatsapp } from "react-icons/fa";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton'
import end from 'react-bootstrap/ButtonGroup';
import { useRef } from 'react';
import {Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com'





const SkinCareHeader = () => {
     // bookappointment script 
  const form = useRef()
  const [data, updateData] = useState({
      Name: "",
      Email: '',
      Number: '',
      Subject: '',
      Message: ''
    })

  const { Name, Email, Number, Subject, Message } = data;
  
  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_yb', 'template_oea', e.target, '7Y-oXT2ydz4')
      .then((result) => {
        alert("Successfully Booked Your Appointment ")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [services, setServices] = useState(false)
    const [submenu1, setSubMenu1] = useState(false)
    const [submenu2, setSubMenu2] = useState(false)
    const [submenu3, setSubMenu3] = useState(false)
    const [submenu4, setSubMenu4] = useState(false)
    const [submenu5, setSubMenu5] = useState(false)
    const [submenu6, setSubMenu6] = useState(false)
    const [gallery, setGallery] = useState(false)
    const [menubar, setmenubar] = useState(false)
    const [submenu1Sub, setSubMenu1Sub] = useState(false)
    const [eyebrowsmenu, seteyebrowsmenu] = useState(false)
    const [LipsMenu, setLipsmenu] = useState(false)
    return (
        <>
            {/* <nav>
                <div class="navbar">
                    <img src='LotusLogo1.png' alt='lotus logo' ></img>
                    <CiMenuFries className='menubar' onClick={() => { setmenubar(!menubar) }} />
                    <div className={menubar ? "menunav" : "nav-links"}>
                        <ul className='links'>
                            <li className='main'><a className='mainlink' onMouseOver={() => { setServices(false) }}>HOME</a></li>
                            <li className='main'><a className='mainlink' onMouseOver={() => { setServices(false) }}>ABOUT US</a></li>
                            <li className='main'><a className='mainlink' onMouseOver={() => { setServices(true); setSubMenu1(false); setSubMenu2(false); setSubMenu3(false); setSubMenu4(false); setSubMenu5(false); setSubMenu6(false); setGallery(false) }}>SERVICES</a>
                                {
                                    services && <ul className='menu' onMouseLeave={() => { setServices(false) }}>
                                        <li><a onMouseOver={() => { setSubMenu1(true); setSubMenu2(false); setSubMenu3(false); setSubMenu4(false); setSubMenu5(false); setSubMenu6(false) }}>Skin Treatments</a>
                                            {
                                                submenu1 && <ul className='sub-menu1' onMouseLeave={() => { setServices(true) }}>
                                                    <li><a className="p-2" onMouseOver={() => { setSubMenu1Sub(true) }}>PMU</a>
                                                        {
                                                            submenu1Sub && <ul onMouseLeave={() => { setSubMenu1Sub(false) }}>
                                                                <li><a onMouseOver={() => { seteyebrowsmenu(true) }}>Eyebrows</a>
                                                                    {
                                                                        eyebrowsmenu && <ul onMouseLeave={() => { seteyebrowsmenu(false) }}>
                                                                            <li><a href=''>Micro Blading</a></li>
                                                                            <li><a href=''>Micro Pigmentation</a></li>
                                                                            <li><a href=''>Ombre Powder Brows</a></li>
                                                                            <li><a href=''>Combination Brows</a></li>
                                                                        </ul>
                                                                    }
                                                                </li>
                                                                <li><a href='' onMouseEnter={() => { setLipsmenu(true) }}>Lips</a>
                                                                    {
                                                                        LipsMenu && <ul onMouseLeave={() => { setLipsmenu(false) }}>
                                                                            <li><a href=''>Lip Neutralisation</a></li>
                                                                            <li><a href=''>Semi-Permanent Lip Tint</a></li>
                                                                        </ul>
                                                                    }
                                                                </li>
                                                            </ul>
                                                        }
                                                    </li>
                                                    <li><a href="/" className="p-2">Microdermabrasion</a></li>
                                                    <li><a href="/" className="p-2">Acne Treatment</a></li>
                                                    <li><a href="/" className="p-2">Customised Skin Care</a></li>
                                                    <li><a href="/" className="p-2">Wart Removal</a></li>
                                                    <li><a href="/" className="p-2">Melasma Management</a></li>
                                                </ul>
                                            }
                                        </li>
                                        <li><a onMouseOver={() => { setSubMenu2(true); setSubMenu1(false); setSubMenu3(false); setSubMenu4(false); setSubMenu5(false); setSubMenu6(false) }}>Hair Treatments</a>
                                            {
                                                submenu2 && <ul className='sub-menu2' onMouseLeave={() => { setServices(true) }}>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                </ul>
                                            }
                                        </li>
                                        <li><a onMouseOver={() => { setSubMenu3(true); setSubMenu2(false); setSubMenu4(false); setSubMenu1(false); setSubMenu5(false); setSubMenu6(false) }}>Laser Treatments</a>
                                            {
                                                submenu3 && <ul className='sub-menu3' onMouseLeave={() => { setServices(true) }}>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                </ul>
                                            }
                                        </li>
                                        <li><a onMouseOver={() => { setSubMenu4(true); setSubMenu3(false); setSubMenu5(false); setSubMenu1(false); setSubMenu2(false); setSubMenu6(false) }}>Medi Facials</a>
                                            {
                                                submenu4 && <ul className='sub-menu4' onMouseLeave={() => { setServices(true) }}>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                </ul>
                                            }
                                        </li>
                                        <li><a onMouseOver={() => { setSubMenu5(true); setSubMenu4(false); setSubMenu6(false); setSubMenu1(false); setSubMenu2(false); setSubMenu3(false) }}>Aesthetic Treatment</a>
                                            {
                                                submenu5 && <ul className='sub-menu5' onMouseLeave={() => { setServices(true) }}>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                </ul>
                                            }
                                        </li>
                                        <li><a onMouseOver={() => { setSubMenu6(true); setSubMenu5(false); setSubMenu1(false); setSubMenu2(false); setSubMenu3(false); setSubMenu4(false) }}>IU-Threapy</a>
                                            {
                                                submenu6 && <ul className='sub-menu6' onMouseLeave={() => { setServices(true) }}>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                    <li><a href="/" className="p-2">submenu1</a></li>
                                                </ul>
                                            }
                                        </li>
                                    </ul>
                                }
                            </li>
                            <li className='main'><a className='mainlink' onMouseOver={() => { setServices(false); setGallery(true) }}>GALLERY
                                {
                                    gallery && <ul className='menu' onMouseLeave={() => { setGallery(false) }} >
                                        <li><a href="/" className="p-2">BLOGS</a></li>
                                        <li><a href="/" className="p-2">IMAGES</a></li>
                                        <li><a href="/" className="p-2">VIDEOS</a></li>
                                    </ul>
                                }
                            </a></li>
                            <li className='main'><a className='mainlink' onMouseOver={() => { setServices(false); setGallery(false) }}>CONTACT</a></li>
                        </ul>
                    </div>

                </div>
            </nav> */}
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbar shadow-sm p-2 mb-5 bg-body rounded" fixed="top">
                <Container>
                    <img src='skinlogo2.png' alt='lotus logo' className='image1'></img>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto" navbarScroll>
                            <Nav.Link href="Skin_And_Hair_Care_Home" className='navitems'>HOME</Nav.Link>
                            <Nav.Link href="#features" className='navitems'>ABOUT US</Nav.Link>
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic5">
                                    SERVICES
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="menuitem5 mt-2">

                                    <DropdownButton drop="end" variant='' title="Skin Treatment" className='dropdownbtn' id='dropdown-basic2' >
                                        <DropdownButton drop="end" variant='' title="PMU" className='dropdownbtn' id='dropdown-basic2'>
                                            <DropdownSubmenu href="#action/3.7" title="Eyebrows" className='dropdownbtn2' id='dropdown-basic3'>
                                                <NavDropdown.Item className='dropitem' href="Microblading_Treatment_In_Guntur" >Micro Blading</NavDropdown.Item>
                                                <NavDropdown.Item className='dropitem' href="Micro_Pigmentation_Treatment_In_Guntur">Micro Pigmentation</NavDropdown.Item>
                                                <NavDropdown.Item className='dropitem' href="Ombre_Powder_Brows_Treatment_In_Guntur">Ombre Powder Brows</NavDropdown.Item>
                                            </DropdownSubmenu>
                                            <DropdownSubmenu href="#action/3.7" title="Lips" className='dropdownbtn2' id='dropdown-basic3'>
                                                <NavDropdown.Item className='dropitem' href="Lip_Neutralisation_Treatment_In_Guntur">Lip Neutralisation</NavDropdown.Item>
                                                <NavDropdown.Item className='dropitem' href="Semi_Permanent_Lip_Tint_Treatment_In_Guntur">Semi-Permanent Lip Tint</NavDropdown.Item>
                                               
                                            </DropdownSubmenu>
                                        </DropdownButton>
                                        <NavDropdown.Item className='dropitem' href="Microdermabrasion_Treatment_In_Guntur">Microdermabrasion </NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Acne_treatment_in_Guntur">Acne Treatment</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Customized_Skin_Care_Treatment_In_Guntur">Customised Skin Care</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Wart_Removal_Treatment_in_Guntur">Wart Removal</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Melasma_Management_Treatment_In_Guntur">Melasma Management</NavDropdown.Item>


                                    </DropdownButton>
                                    <DropdownButton drop="end" variant='' title="Hair Treatment" className='dropdownbtn' id='dropdown-basic2'>
                                        <NavDropdown.Item className='dropitem' href="PRP_Treatment_in_Guntur">PRP</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Biotin_And_PRP_treatment_in_Guntur">Biotin + PRP</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="GFC_Hair_Treatment_in_Guntur">GFC</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Alopecia_Areata_treatment_in_Guntur">Alopecia Areata</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Stem_Cell_Therapy_in_Guntur">Stem Cell Therapy</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Hair_Transplantation_treatment_in_Guntur">Hair Transplantation</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Dandruff_Treatment_in_Guntur">Dandruff Treatment</NavDropdown.Item>
                                    </DropdownButton>
                                    <DropdownButton drop="end" variant='' title="Laser Treatment" className='dropdownbtn' id='dropdown-basic2'>
                                        <NavDropdown.Item className='dropitem' href="Acne_And_Acne_Marks_Treatment_In_Guntur">Acne & Acne Marks </NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Pigmentation_Treatment_in_Guntur">Pigmentation</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Freckles_Treatment_in_Guntur">Freckles</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Wrinkless_Treatment_In_Guntur">Wrinkless</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Under_Dark_Circles_Treatment_In_Guntur">Under Dark Circles</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Skin_Tightening_Treatment_In_Guntur">Skin Tightening</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Hair_Removal_Treatment_in_Guntur">Hair Removal</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href='Tattoo_Remova_Treatment_in_Guntur'>Tatoo Removal</NavDropdown.Item>
                                    </DropdownButton>
                                    <DropdownButton drop="end" variant='' title="Medi Facial Treatment" className='dropdownbtn' id='dropdown-basic2'>
                                        <NavDropdown.Item className='dropitem' href="Hydra_Facial_Treatment_In_Guntur">Hydra Facial</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="OxyGeneo_Facial_Treatment_In_Guntur">Oxygeneo-Facial</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Carbon_Peel_Laser_Treatment_In_Guntur">carbon Peel Laser</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Chemical_Peels_Treatment_In_Guntur">Chemical Peels</NavDropdown.Item>
                                        {/* <NavDropdown.Item className='dropitem' href="#action/9.1">tr... somal Facial</NavDropdown.Item> */}
                                    </DropdownButton>
                                    <DropdownButton drop="end" variant='' title="Aesthetic Treatments" className='dropdownbtn' id='dropdown-basic2'>
                                        <NavDropdown.Item className='dropitem' href="PDO_COG_Threads_Treatment_In_Guntur">PDO COG Threads</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Lip_Fillers_Treatment_In_Guntur">Lip Fillers</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Under_Eye_Fillers_Treatment_In_Guntur">Under Eye Fillers</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Botox_Treatment_In_Guntur">Botox</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Barbie_Botox_treatment_In_Guntur">Barbie Botox</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Baby_Botox_Treatment_In_Guntur">Baby Botox</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="Nano_needling_Treatment_In_Guntur">Nano needling</NavDropdown.Item>
                                    </DropdownButton>
                                    <DropdownButton drop="end" variant='' title="IV-Therapy" className='dropdownbtn' id='dropdown-basic2'>
                                        <NavDropdown.Item className='dropitem' href="IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur">IV-Glutathione Drip For Whole Body Whitening</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="IV_Lipolysis_Treatment_in_Guntur">IV-Lipolysis</NavDropdown.Item>
                                    </DropdownButton>
                                </Dropdown.Menu>

                            </Dropdown>
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic5">
                                    GALLERY
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="mega-menu5 mt-2">
                                    <Dropdown.Item href="SkinBlogs" className='galleryitemed'>Blogs</Dropdown.Item>
                                    <Dropdown.Item href="Skin_and_hair_care_images" className='galleryitemed'>Images</Dropdown.Item>
                                    <Dropdown.Item href="Skin_Videos" className='galleryitemed'>Videos</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </Nav>
                        <Nav>
                            <Nav.Link href="SkinCareContact" className="navitems">CONTACT</Nav.Link>
                            <Link to="#" ><button className="btn" id="btnchange" onClick={handleShow} >BOOK APPOINTMENT</button></Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
            <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{ color: '#d4296b',textAlign:'center' }}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
              <div className="help-block with-errors"></div>
            </Form.Group>
            <button type="submit" className="btn " name="contact" style={{ backgroundColor: "#d4296b", color: "whitesmoke" }} >Send Message</button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </Form>
        </Modal.Body>
      </Modal>

            {/* <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbar shadow-sm p-2 mb-5 bg-body rounded" fixed="top">
                <Container>
                    <img src='LotusLogo1.png' alt='lotus logo' ></img>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto" navbarScroll>
                            <Nav.Link href="#features" className='navitems'>HOME</Nav.Link>
                            <Nav.Link href="#features" className='navitems'>ABOUT US</Nav.Link>
                            <NavDropdownMenu href="#action/3.1" title="SERVICES" id="collasible-nav-dropdown">
                                <DropdownButton>
                                <DropdownSubmenu href="#action/3.7" title="Skin Treatment" >
                                        <DropdownSubmenu href="#action/3.7" title="PMU" >
                                            <DropdownSubmenu href="#action/3.7" title="Eyebrows">
                                                <NavDropdown.Item className='dropitem' href="#action/9.1">Micro Blading</NavDropdown.Item>
                                                <NavDropdown.Item className='dropitem' href="#action/9.1">Micro Pigmentation</NavDropdown.Item>
                                                <NavDropdown.Item className='dropitem' href="#action/9.1">Ombre Powder Brows</NavDropdown.Item>
                                            </DropdownSubmenu>
                                            <DropdownSubmenu href="#action/3.7" title="Lips">
                                                <NavDropdown.Item className='dropitem' href="#action/9.1">Lip Newtralisation</NavDropdown.Item>
                                                <NavDropdown.Item className='dropitem' href="#action/9.1">Semi-Permanent Lip Tint</NavDropdown.Item>
                                                <NavDropdown.Item className='dropitem' href="#action/9.1"></NavDropdown.Item>
                                            </DropdownSubmenu>
                                        </DropdownSubmenu>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Microdermabrasion </NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Acne Treatment</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Customised Skin Care</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Wart Removal</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Melasma Management</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1"></NavDropdown.Item>
                                    </DropdownSubmenu>
                                </DropdownButton>
                                    
                                    <DropdownSubmenu href="#action/3.7" title="Hair Treatment">
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">PRP</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Biotin + PRP</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">GFC</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Alopecia Areata</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Stem Cell Therapy</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Hair Transplantation</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Dandruff Treatment</NavDropdown.Item>
                                    </DropdownSubmenu>
                                    <DropdownSubmenu href="#action/3.7" title="Laser Treatment">
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Acne & Acne Marks </NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Pigmentation</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Freckles</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Wrinkless</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Under Dark Circles</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Skin Tightening</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Hair Removal</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href='/'>Tatoo Removal</NavDropdown.Item>
                                    </DropdownSubmenu>
                                    <DropdownSubmenu href="#action/3.7" title="Medi Facial Treatment">
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Hydro Facial</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Oxyzeno-Facial</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">carbon Peel Laser</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Chemical Peels</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">tr... somal Facial</NavDropdown.Item>

                                    </DropdownSubmenu>
                                    <DropdownSubmenu href="#action/3.7" title="Aesthetic Treatments">
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">PDO COG Threads</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Lip Fillers</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Under Eye Fillers</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Botox</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Barbie Botox</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Barby Botox</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">Nano needling</NavDropdown.Item>
                                    </DropdownSubmenu>
                                    <DropdownSubmenu href="#action/3.7" title="IV-Therapy">
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">IV-Glutathiane Drip For Whole Body Whitening</NavDropdown.Item>
                                        <NavDropdown.Item className='dropitem' href="#action/9.1">IV-Liplysis</NavDropdown.Item>

                                    </DropdownSubmenu>
                                
                            </NavDropdownMenu>
                            <NavDropdownMenu href="#action/3.1" title="GALLERY" id="collasible-nav-dropdown">
                                <Dropdown className='dropDown'>
                                    <NavDropdown.Item className='dropitem' href=''>Blogs</NavDropdown.Item>
                                    <NavDropdown.Item className='dropitem' href=''>Images</NavDropdown.Item>
                                    <NavDropdown.Item className='dropitem' href=''>Videos</NavDropdown.Item>
                                </Dropdown>
                            </NavDropdownMenu>
                        </Nav>
                        <Nav>
                            <Nav.Link href="#deets" className="navitems">CONTACT</Nav.Link>
                            <Nav.Link href="#deets" className="navitems"><FaWhatsapp className='headerWhatsapp' /> 999999999</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}


        </>
    )
}

export default SkinCareHeader