import React from 'react'
import { TfiEmail } from "react-icons/tfi";
import { FiPhoneCall } from "react-icons/fi";
import { RiHomeOfficeLine } from "react-icons/ri";

const Footer = () => {
    return (
        <> <div className='footer'>
            <div className='container' >
                <div className='row'>
                    <div className='col-md-3 col-sm-12 text-center'>
                        <img src='skinlogo1.png' alt=''></img>
                        <p className='footerText'> At Lotus Dental Skin and Hair Care, we believe everyone deserves to look and feel their best. Conveniently located in Guntur, our state-of-the-art facility offers a comprehensive range of services to meet all your dental, skin, and hair care needs.</p>
                    </div>
                    <div className='col-md-6 col-sm-12 text-center'>
                        <h6>USEFULL LINKS</h6>
                        <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                       <ul style={{textAlign:'left'}}>
                            <li><a href='/Acne_treatment_in_Guntur' style={{color:'white'}}>Acne Treatment</a></li>
                            <li><a href='/Customized_Skin_Care_Treatment_In_Guntur' style={{color:'white'}}>Customized Skin Care</a></li>
                            <li><a href='/Wart Removal' style={{color:'white'}}>Wart Removal</a></li>
                            <li><a href='/Biotin_And_PRP_treatment_in_Guntur' style={{color:'white'}}>Biotin and PRP</a></li>
                            <li><a href='/Hair_Transplantation_treatment_in_Guntur' style={{color:'white'}}>Hair Transplantation</a></li>
                            <li><a href='/Acne_And_Acne_Marks_Treatment_In_Guntur' style={{color:'white'}}>Acne and Acne Marks</a></li>
                            <li><a href='/Under_Dark_Circles_Treatment_In_Guntur' style={{color:'white'}}>Under Dark Circles</a></li>
                            <li><a href='/Dandruff_Treatment_in_Guntur' style={{color:'white'}}> Dandruff Treatment</a></li>
                        </ul>
                       </div>
                       <div className='col-md-6 col-sm-12'>
                       <ul style={{textAlign:'left'}}>
                            <li><a href='/Hydra_Facial_Treatment_In_Guntur' style={{color:'white'}}>HydraFacial</a></li>
                            <li><a href='/Carbon_Peel_Laser_Treatment_In_Guntur' style={{color:'white'}}>Carbon Peel Laser</a></li>
                            <li><a href='/Chemical_Peels_Treatment_In_Guntur' style={{color:'white'}}> Chemical Peels</a></li>
                            <li><a href='/PDO_COG_Threads_Treatment_In_Guntur' style={{color:'white'}}>PDO COG Threads</a></li>
                            <li><a href='/Barbie_Botox_treatment_In_Guntur' style={{color:'white'}}>Barbie Botox</a></li>
                            <li><a href='/IV_Glutathione_Drip_for_Whole_Body_Whitening_treatment_In_Guntur' style={{color:'white'}}>IV-Glutathione Drip for Whole Body Whitening</a></li>
                            <li><a href='/IV_Lipolysis_Treatment_in_Guntur' style={{color:'white'}}>IV-Lipolysis</a></li>
                            <li><a href='/' style={{color:'white'}}></a></li>
                        </ul>
                       </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <h6>CONTACT</h6>
                        <p style={{textAlign:'left'}}><RiHomeOfficeLine />  Naidupeta 2nd line, Amaravathi Road, Guntur</p>
                        <p style={{textAlign:'left'}}><TfiEmail /> Lotusdental5@gmail.com</p>
                        <p style={{textAlign:'left'}}><FiPhoneCall /> 9701490546</p>
                    </div>
                </div>
            </div>
            </div>
         
        </>
    )
}

export default Footer